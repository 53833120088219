<template>
  <div>

    <v-row class="d-flex justify-space-around py-5">
      <v-card
        width="85%"
        outlined
        elevation="4"
      >
        <v-list>
          <v-list-item v-for="(user, index) in users" :key="index">
            <v-col xl="3" lg="4" md="4" sm="auto" align="center">
              <v-list-item-title>
                {{ user.username }}
              </v-list-item-title>
            </v-col>
            <v-col xl="3" lg="4" md="4" sm="auto" align="left">
              <v-btn
                color="primary--text"
                class="mb-2"
                @click="openChangePasswordDialog(user)"
                :disabled="isAdmUser(user) || !isAdministrator"
              >
                {{ $t('user_settings.change_password') }}
              </v-btn>
            </v-col>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>

    <v-dialog
      v-model="changePasswordDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('user_settings.change_password_card.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="changePasswordFormIsValid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="changePasswordForm.newPassword"
                    :error-messages="changePasswordFormErrors.new_password"
                    @input="changePasswordFormErrors.new_password=[]"
                    :label="$t('user_settings.change_password_card.password_label')"
                    type="password"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="changePasswordForm.repeatedNewPassword"
                    :error-messages="changePasswordFormErrors.re_new_password"
                    @input="changePasswordFormErrors.re_new_password=[]"
                    :label="$t('user_settings.change_password_card.confirm_password_label')"
                    type="password"
                    :rules="[rules.required, rules.matchPasswords]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="error-message red--text" v-if="changePasswordFormErrors.non_field_errors.length != 0">
                <span v-for="(error, index) in changePasswordFormErrors.non_field_errors" :key="index"
                  v-text="error + ' '"
                />
              </v-row>
            </v-container>
          </v-form>
          <small>*{{ $t('user_settings.change_password_card.indicates_required') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary--text"
            @click="cancelPasswordDialog"
          >
            {{ $t('user_settings.change_password_card.close_button') }}
          </v-btn>
          <v-btn
            color="primary--text"
            :disabled="!changePasswordFormIsValid"
            @click="savePasswordDialog"
          >
            {{ $t('user_settings.change_password_card.save_button') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div> 
</template>

<script>
  export default {
    name: 'UsersSettings',
    data: () => ({
      changePasswordDialog: false,
      changePasswordFormIsValid: false,

      changePasswordFormErrors: {
        non_field_errors: [],
        new_password: [],
        re_new_password: [],
      },
      changePasswordFormErrorsDefault: {
        non_field_errors: [],
        new_password: [],
        re_new_password: [],
      },
      changePasswordForm: {
        newPassword: '',
        repeatedNewPassword: '',
      },
      changePasswordFormDefault: {
        newPassword: '',
        repeatedNewPassword: '',
      },

      editedUserId: -1
    }),
    computed: {
      rules () {
        return {
          required: value => !!value || this.$t('user_settings.change_password_card.rules.required'),
          matchPasswords: value => value === this.changePasswordForm.newPassword || this.$t('user_settings.change_password_card.rules.passwords_not_match')
        }
      },
      isAdministrator() {
        return this.$store.getters.isAdministrator
      },
      users () {
        return this.$store.state.users
      },
    },
    methods: {
      openChangePasswordDialog(user) {
        this.changePasswordDialog = true
        this.editedUserId = user.id
      },
      isAdmUser(user) {
        return user.role === 'administrator'
      },
      savePasswordDialog() {
        let data = {
          'id': this.editedUserId,
          'data': this.changePasswordForm,
        }
        this.$store.dispatch('changeUserPassword', data)
        .then( () => {
          this.cancelPasswordDialog()
        })
        .catch( errors => {
          Object.assign(this.changePasswordFormErrors, errors)
        })
      },
      cancelPasswordDialog() {
        this.changePasswordDialog = false
        this.changePasswordForm = Object.assign({}, this.changePasswordFormDefault)
        this.changePasswordFormErrors = Object.assign({}, this.changePasswordFormErrorsDefault)
        this.editedUserId = -1
      },
    },
    created() {
      this.$store.dispatch('getUsers')
    },
  }
</script>
